header {
    padding: 36px 0;
    // @media (min-width: 1200px) {
    //     .container {
    //         max-width: calc(100% - (80px * 2));
    //     }
    // }

    @media (min-width: 1700px) {
        .container {
            max-width: 1600px;
        }
    }

    nav {
        > ul {
            li {
                position: relative;
                // &:hover {
                //     .subMenu {
                //         opacity: 1;
                //         visibility: visible;
                //     }
                // }
                a {
                    display: block;
                    padding: 5px 15px;
                    color: #353535 !important;
                    font-weight: 500;
                    border-bottom: 2px solid transparent;
                    @media (min-width: 1540px) {
                        font-size: 18px;
                    }

                    &.active,
                    &:hover {
                        border-color: #00e1ff;
                    }
                    svg {
                        margin-left: 6px;
                        transform: rotate(-90deg);
                        &.active {
                            transform: rotate(0deg);
                        }
                    }
                }
                .subMenu {
                    background: #ffffff;
                    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
                    border-radius: 15px;
                    width: 268px;
                    border-radius: 15px;
                    text-align: center;
                    padding: 15px 0;
                    position: absolute;
                    top: 80px;
                    right: -50px;
                    // opacity: 0;
                    // visibility: hidden;
                    li {
                        padding: 10px 0;
                        a {
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }
    .downloadBtn {
        border: 1px solid #c4c4c4;
    }
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.customModal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    animation: fadeIn 0.3s;
    .modalSkin {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 99;
    }
    .card {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 1000px;
        max-width: 90vw;
        max-height: 90vh;
        transform: translate(-50%, -50%);
        z-index: 101;
    }
    .card-header {
        background-color: transparent;
        padding: 2rem 3rem;
        h1 {
            margin: 0;
            font-weight: 700;
        }
        .closeModal {
            cursor: pointer;
        }
    }
    .card-body {
        overflow: auto;
        height: 100%;
        padding: 3rem;
        &::-webkit-scrollbar {
            width: 6px;
        }
        &::-webkit-scrollbar-thumb {
            background: #888;
        }
    }
}

@media (max-width: 1440px) {
    header {
        .home {
            img {
                height: 40px;
            }
        }
    }
}
@media (max-width: 1199px) {
    header {
        nav {
            &.active {
                left: 0;
            }
            transition: ease 0.3s;
            position: fixed;
            left: -280px;
            top: 0;
            height: 100vh;
            width: 250px;
            z-index: 99;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px, rgba(149, 157, 165, 0.2) 0px 8px 24px;
            > div {
                background-color: #fafafa;
                padding: 15px 15px;
                font-weight: 600;
                margin-bottom: 20px;

                .closeNav {
                    &:hover {
                        color: #00e1ff;
                    }
                }
            }
            > ul {
                height: 100%;
                overflow: auto;
                li {
                    padding: 5px 0;
                    a {
                        font-size: 75%;
                    }
                    .subMenu {
                        position: static;
                        opacity: 1;
                        visibility: visible;
                        width: 90%;
                        margin: 12px auto 0;
                        li {
                            padding: 5px 0;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    header {
        padding: 16px 0;

        .home {
            img {
                height: 24px;
            }
        }
        .downloadBtn {
            font-size: 12px;
            padding: 4px 12px;
            font-weight: 500;
            svg {
                width: 16px;
            }
        }
    }

    .customModal {
        .card-header {
            padding: 1rem 1.5rem;
            h1 {
                font-size: 20px;
            }
        }
        .card-body {
            padding: 1rem 1.5rem;
            p {
                font-size: 14px;
            }
        }
    }
}
