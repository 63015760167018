.homePage {
  .layer1 {
    padding: 122px 0 225px;
    // background-image: url("./hero1.svg"), url("./hero2.svg");
    // background-position: left top, right bottom;
    // background-repeat: no-repeat, no-repeat;
    @media (min-width: 1700px) {
      .container {
        max-width: 1600px;
      }
    }
    h1 {
      font-size: 60px;
      line-height: 73px;
      margin-bottom: 50px;
    }
    .btn {
      font-size: 150%;
    }
  }

  .layer2 {
    padding: 0 0 50px;
    img {
      width: 15%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .promoImages {
    padding: 5px 5px 5px 5px;
    img {
      width: 50%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .layer3 {
    h2 {
      font-size: 60px;
      font-weight: 400;
      font-family: "Comfortaa", cursive;
      sub {
        font-size: 40%;
      }
    }
    h3 {
      font-weight: bold;
      font-size: 36px;
      line-height: 44px;
      color: #353535;
    }
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 40px;
      color: #858585;
    }
    img {
      max-width: 100%;
    }
  }

  .layer4 {
    box-shadow: 0px -20px 35px rgba(0, 0, 0, 0.05);
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 99;
    .bigClose {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#000, 0.5);
      backdrop-filter: blur(5px);
      z-index: 1;
    }
    .container {
      background-color: #fff;
      max-height: 90vh;
      overflow: auto;
      border-radius: 15px;
      position: relative;
      z-index: 2;
    }
    h2 {
      font-size: 60px;
      font-weight: 900;
    }
    figure {
      margin: 0;
      img {
        display: block;
        width: 100%;
        border-radius: 30px;
      }
    }
    .h4 {
      font-weight: bold;
      font-size: 24px;
      line-height: 38px;
      color: #353535;
    }
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 33px;
      /* or 206% */

      color: #858585;
    }
  }

  .layer5 {
    padding: 134px 0;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 99;
    .bigClose {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#000, 0.5);
      backdrop-filter: blur(5px);
      z-index: 1;
    }
    .container {
      background-color: #fff;
      max-height: 90vh;
      overflow: auto;
      border-radius: 15px;
      position: relative;
      z-index: 2;
    }
    h2 {
      font-size: 60px;
      font-weight: 900;
    }
    .h4 {
      font-weight: bold;
      font-size: 24px;
      line-height: 38px;
      color: #353535;
    }
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 33px;
      /* or 206% */

      color: #858585;
    }
  }
  .layer6 {
    padding: 0 0 134px;
    text-align: center;
    // background-image: url("./hero1.svg");
    // background-position: center bottom;
    // background-repeat: no-repeat;
    h2 {
      font-size: 60px;
      font-weight: 900;
    }
    .h4 {
      font-weight: bold;
      font-size: 24px;
      line-height: 38px;
      color: #353535;
    }
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 33px;
      /* or 206% */

      color: #858585;
    }
  }
}

@media (max-width: 1199px) {
  .homePage {
    .layer1 {
      padding: 100px 0 150px;
      h1 {
        font-size: 38px;
        line-height: 62px;
      }
    }
  }
}

@media (max-width: 991px) {
  .homePage {
    .layer3 {
      text-align: center;
      figure {
        img {
          margin: auto;
          display: block;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .homePage {
    .layer1 {
      padding: 55px 0 100px;
      h1 {
        font-size: 22px;
        line-height: 34px;
      }
    }
    .layer3 {
      h2 {
        font-size: 24px;
      }
      h3 {
        font-size: 30px;
        line-height: 35px;
      }
      p {
        font-size: 13px;
        line-height: 28px;
      }
    }
    .layer4 {
      text-align: center;
      h2 {
        font-size: 26px;
      }
      h3 {
        font-size: 30px;
        line-height: 35px;
      }
      p {
        font-size: 13px;
        line-height: 28px;
      }

      .row {
        .row {
          > div {
            margin-bottom: 2rem;
          }
        }
      }
    }
    .layer5 {
      padding: 70px 0;

      h2 {
        font-size: 28px;
      }
      .h4 {
        font-size: 22px;
        margin: 0;
      }
      p {
        line-height: 26px;
        font-size: 14px;
      }
    }
    .layer6 {
      padding: 70px 0;

      h2 {
        font-size: 28px;
      }
      .h4 {
        font-size: 22px;
        margin: 0;
      }
      p {
        line-height: 26px;
        font-size: 14px;
      }
    }
  }
}
