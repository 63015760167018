$theme-colors: (
  "primary": #00e1ff,
  "secondary": #353535,
  "black": #000,
  "ass": #858585,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1600px,
);
// font-family: 'Comfortaa', cursive;
// font-family: 'Montserrat', sans-serif;
$font-family-sans-serif: "Montserrat", sans-serif;
// @import "@fancyapps/ui/dist/fancybox.css";
@import "~bootstrap/scss/bootstrap.scss";

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "react-responsive-carousel/lib/styles/carousel.min.css";

.mat-select-black {
  .MuiSelect-select:focus {
    background-color: transparent !important;
  }
  .MuiSelect-icon {
    color: #000000 !important;
  }
}
.mat-select-dark2 {
  .MuiSelect-select:focus {
    background-color: transparent !important;
  }
  .MuiSelect-icon {
    color: #94a2b3 !important;
  }
}

body {
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #0056e9;
  }
  .MuiInput-underline:before {
    display: none !important;
  }
  .MuiInput-underline:after {
    display: none !important;
  }
  .MuiFormControl-marginNormal {
    margin: 0.5rem 0;
    padding: 0 1.5rem;
    width: 100%;
  }
  .MuiInputBase-input {
    font-weight: 500;
    // font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 0.875rem;

    // #{$fontSize / 16}rem
  }
  .MuiIconButton-root {
    padding: 0;
    span {
      width: auto !important;
    }
  }
  .MuiTypography-body1 {
    font-weight: 500;
    font-size: 0.875rem;
    padding-left: 6px;
    color: #000000;
  }
  .MuiRadio-colorSecondary.Mui-checked {
    color: #0056e9;
  }
  .MuiSlider-track {
    height: 4px;
    border-radius: 4px;
  }
  .MuiSlider-rail {
    height: 4px;
    border-radius: 4px;
  }
  .MuiSlider-thumb {
    width: 26px;
    height: 26px;
    color: #fff;
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.25) !important;
    margin-top: -12px;
  }
}
p {
  color: #858585;
}
.btn-xxl {
  padding: 10px 20px;
  @media (min-width: 1540px) {
    padding: 10px 20px;
    font-size: 20px;
    box-shadow: none;
  }
}

.form-control {
  height: 3rem;
  line-height: 3rem;
  padding: 0 2rem;
}
.btn-pad {
  padding: 0.7rem;
}
.btn-pad-cap {
  padding: 0.7rem 1.5rem;
}

// font-family: 'Poppins', sans-serif;
// font-family: 'Roboto', sans-serif;

body {
  user-select: none;
  &.noScroll {
    overflow: hidden;
  }
}
* {
  outline: none !important;
}
a {
  transition: ease 0.3s;
  text-decoration: none !important;
  cursor: pointer;
}
svg {
  transition: ease 0.3s;
}
button {
  transition: ease 0.3s;
  -webkit-appearance: none;
}

.bg-fade-primary {
  background-color: #f4f8ff;
}

.oft-cov {
  object-fit: cover;
}

.ff-roboto {
  font-family: "Roboto", sans-serif;
}
.ff-poppins {
  font-family: "Poppins", sans-serif;
}
.cp {
  cursor: pointer;
}

.text-h-dark {
  &:hover {
    color: #000 !important;
  }
}

select {
  -webkit-appearance: none;
  cursor: pointer;
  // background-image: url("assets/images/selectArrow.svg");
  background-position: 90% center;
  background-repeat: no-repeat;
}

@for $fw from 0 to 10 {
  .fw-#{$fw} {
    font-weight: $fw * 100 !important;
  }
}
@for $fade from 0 to 10 {
  .fade-#{$fade} {
    opacity: calc($fade / 10) !important;
  }
}

// ************ Font size  *************
$fontSizeLastValue: 50;
@for $fontSize from 0 through $fontSizeLastValue {
  .fs-#{$fontSize} {
    font-size: #{calc($fontSize / 16)}rem !important;
  }
}
@each $deviceName, $screenWidth in $grid-breakpoints {
  @media (min-width: $screenWidth) {
    @for $fontSize from 0 through $fontSizeLastValue {
      .fs-#{$deviceName}-#{$fontSize} {
        font-size: #{calc($fontSize / 16)}rem !important;
      }
    }
  }
}

.fileUpload {
  width: 164px;
  height: auto;
  // box-shadow: 0px 3px 24px #dfdada;
  border-radius: 10px;
  text-align: center;
  background-color: #f2f7ff;
  border: 1px solid #bbd4ff;
  color: #0056e9;
  line-height: 1;
  padding: 1.5rem 0;
  cursor: pointer;
  transition: ease 0.3s;
  &:hover {
    background-color: #fff;
    box-shadow: 0px 3px 24px #dfdada;
  }
}

.label {
  width: 140px;
  text-align: right;
}

.inputBox {
  width: 319px;
  border-radius: 25px;
  overflow: hidden;
  border: 1px solid #e9e9e9;
  input {
    border: 0;
  }
  span {
    width: 64px;
    text-align: center;
    line-height: 3rem;
  }
}

.customSelect {
  width: 319px;
  border-radius: 25px;
  overflow: hidden;
  border: 1px solid #e9e9e9;
  padding: 0.4rem 1rem;
}

textarea {
  height: 180px;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #e9e9e9;
  resize: none;
}

.innerBAnner {
  // min-height: 100vh;
  padding: 10rem 0;
  display: flex;
  align-items: center;
  @media (min-width: 1700px) {
    padding: 14rem 0;
    .container {
      max-width: 1600px;
    }
    h1 {
      font-size: 60px;
      line-height: 73px;
    }
  }
  h1 {
    font-weight: 900;
    font-size: 50px;
    line-height: 63px;
    color: #353535;
    margin-bottom: 30px;
  }
  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #858585;
  }
}

@media (max-width: 1199px) {
  .innerBAnner {
    padding: 2rem 0 3rem;
    h1 {
      font-size: 38px;
      line-height: normal;
    }
  }
}

@media (max-width: 991px) {
  .innerBAnner {
    text-align: center;
  }
}
